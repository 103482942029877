export enum ENVIRONMENTS {
  LOCAL = "local",
  DEV = "dev",
  STAG = "stag",
  PROD = "prod",
}

export const ENV_CONFIGS = {
  LOCAL: {
    FACEBOOK_APP_ID: "7567197693388180",
    API_URL: "https://dev.admin.ucall.vn/api",
    ZALO_PERSONAL_API: "http://localhost:2000/",
    NEXT_API_URL: "/api",
    APP_V2_URL: "http://localhost:8000",
    ZALO_REDIRECT_URL: "https://v3.ucall.vn/dashboard/callback/zalo",
    ZALO_APP_ID: "1760658625456417303",
    RECAPTCHA_KEY: "6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9",
    SHOW_DEV_MENU: true,
    PHONE_IFRAME_SYNC_SPEED: 3000,
    FIREBASE_API_KEY: "AIzaSyCZXI4zl3jk-wftG4Zbh2eDj-GUas0akd4",
    FIREBASE_AUTH_DO_MAIN: "ucall-v3.firebaseapp.com",
    FIREBASE_PROJECT_ID: "ucall-v3",
    FIREBASE_STORAGE_BUCKET: "ucall-v3.appspot.com",
    FIREBASE_MESSAGING_SENDER_ID: "108029656062",
    FIREBASE_APP_ID: "1:108029656062:web:4101191a7337746f6cdeaf",
    FIREBASE_MEASUREMENT_ID: "G-TNTCME2TR6",
    FIREBASE_VAPID_KEY:
      "BLlTSX1f1W-T-cxzWOVYyURJAa_m5kljTBzyLQeQYqvLYS0iHaK4kjr6pNHapFFFym5CfiyKznMc83f263PkdnA",
    FEATURE_FLAGS: {
      OMNI_CHANNEL_CHAT: true,
      OMNI_CHANNEL_ZALO_PERSONAL: true,
    },
  },
  DEV: {
    FACEBOOK_APP_ID: "7567197693388180",
    API_URL: "https://dev.admin.ucall.vn/api",
    ZALO_PERSONAL_API: "https://dev.zalo.ucall.vn/",
    NEXT_API_URL: "/api",
    APP_V2_URL: "https://iframe.dev.growth.ucall.vn",
    ZALO_REDIRECT_URL: "https://dev.app.ucall.vn/dashboard/callback/zalo",
    ZALO_APP_ID: "101862512239936739",
    RECAPTCHA_KEY: "6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9",
    SHOW_DEV_MENU: true,
    PHONE_IFRAME_SYNC_SPEED: 3000,
    FIREBASE_API_KEY: "AIzaSyCZXI4zl3jk-wftG4Zbh2eDj-GUas0akd4",
    FIREBASE_AUTH_DOMAIN: "ucall-v3.firebaseapp.com",
    FIREBASE_PROJECT_ID: "ucall-v3",
    FIREBASE_STORAGE_BUCKET: "ucall-v3.appspot.com",
    FIREBASE_MESSAGING_SENDER_ID: "108029656062",
    FIREBASE_APP_ID: "1:108029656062:web:4101191a7337746f6cdeaf",
    FIREBASE_MEASUREMENT_ID: "G-TNTCME2TR6",
    FIREBASE_VAPID_KEY:
      "BLlTSX1f1W-T-cxzWOVYyURJAa_m5kljTBzyLQeQYqvLYS0iHaK4kjr6pNHapFFFym5CfiyKznMc83f263PkdnA",
    FEATURE_FLAGS: {
      OMNI_CHANNEL_CHAT: true,
      OMNI_CHANNEL_ZALO_PERSONAL: true,
    },
  },
  STAG: {
    FACEBOOK_APP_ID: "7567197693388180",
    API_URL: "https://stag.admin.ucall.vn/api",
    ZALO_PERSONAL_API: "https://dev.zalo.ucall.vn/",
    NEXT_API_URL: "/api",
    APP_V2_URL: "https://iframe.stag.growth.ucall.vn",
    ZALO_REDIRECT_URL: "https://stag.app.ucall.vn/dashboard/callback/zalo",
    ZALO_APP_ID: "1760658625456417303",
    RECAPTCHA_KEY: "6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9",
    SHOW_DEV_MENU: true,
    PHONE_IFRAME_SYNC_SPEED: 800,
    FIREBASE_API_KEY: "AIzaSyCZXI4zl3jk-wftG4Zbh2eDj-GUas0akd4",
    FIREBASE_AUTH_DOMAIN: "ucall-v3.firebaseapp.com",
    FIREBASE_PROJECT_ID: "ucall-v3",
    FIREBASE_STORAGE_BUCKET: "ucall-v3.appspot.com",
    FIREBASE_MESSAGING_SENDER_ID: "108029656062",
    FIREBASE_APP_ID: "1:108029656062:web:4101191a7337746f6cdeaf",
    FIREBASE_MEASUREMENT_ID: "G-TNTCME2TR6",
    FIREBASE_VAPID_KEY:
      "BLlTSX1f1W-T-cxzWOVYyURJAa_m5kljTBzyLQeQYqvLYS0iHaK4kjr6pNHapFFFym5CfiyKznMc83f263PkdnA",
    FEATURE_FLAGS: {
      OMNI_CHANNEL_CHAT: true,
      OMNI_CHANNEL_ZALO_PERSONAL: true,
    },
  },
  PROD: {
    FACEBOOK_APP_ID: "7567197693388180",
    // FACEBOOK_APP_ID: "397016910079219",
    API_URL: "https://admin.ucall.vn/api",
    ZALO_PERSONAL_API: "https://dev.zalo.ucall.vn/",
    NEXT_API_URL: "/api",
    APP_V2_URL: "https://iframe.growth.ucall.vn",
    ZALO_REDIRECT_URL: "https://app.ucall.vn/dashboard/callback/zalo",
    ZALO_APP_ID: "916677778758798537",
    RECAPTCHA_KEY: "6LdebFYdAAAAAKwFmzUMUx8EUVLZi_cqEA01-Db9",
    SHOW_DEV_MENU: false,
    PHONE_IFRAME_SYNC_SPEED: 800,
    FIREBASE_API_KEY: "AIzaSyCZXI4zl3jk-wftG4Zbh2eDj-GUas0akd4",
    FIREBASE_AUTH_DOMAIN: "ucall-v3.firebaseapp.com",
    FIREBASE_PROJECT_ID: "ucall-v3",
    FIREBASE_STORAGE_BUCKET: "ucall-v3.appspot.com",
    FIREBASE_MESSAGING_SENDER_ID: "108029656062",
    FIREBASE_APP_ID: "1:108029656062:web:4101191a7337746f6cdeaf",
    FIREBASE_MEASUREMENT_ID: "G-TNTCME2TR6",
    FIREBASE_VAPID_KEY:
      "BLlTSX1f1W-T-cxzWOVYyURJAa_m5kljTBzyLQeQYqvLYS0iHaK4kjr6pNHapFFFym5CfiyKznMc83f263PkdnA",
    FEATURE_FLAGS: {
      OMNI_CHANNEL_CHAT: true,
      OMNI_CHANNEL_ZALO_PERSONAL: false,
    },
  },
};
